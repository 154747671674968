import "../styles/pages/page-policy.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const Policy = ({ pageContext }) => {
  const policyContent = pageContext.content;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.seo?.title}
        image={require("assets/images/company-bg.png").default}
      />
      <section className="section-policy">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: policyContent }}></div>
        </div>
      </section>
    </Layout>
  );
};

export default Policy;
